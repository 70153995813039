<template>
  <div id="square-button-group-container" class="d-flex flex-row flex-wrap justify-center align-center mx-4 mt-8">
    <SquareButton v-for="b in buttons" :key="b.title" :title="b.title" :link="b.link" :icon="b.icon"/>
  </div>
</template>

<script>
import SquareButton from './SquareButton';

export default {
  name: 'SquareButtonGroup',
  components: {SquareButton},
  props: {
    buttons: {type: Array, required: true}
  }
};
</script>

<style scoped>
#square-button-group-container {
  margin-bottom: 120px;
}
</style>
