<template>
  <div id="square-button-container" @click="$router.push(link).catch(ignored => {})"
       class="d-flex flex-column flex-nowrap justify-center align-center">
    <v-icon color="#ffffff" size="120">{{ icon }}</v-icon>
    <div id="square-button-title">{{ $t(title) }}</div>
  </div>
</template>

<script>
export default {
  name: 'SquareButton',
  props: {
    title: {type: String, required: true},
    icon: {type: String, required: true},
    link: {type: String, required: true}
  }
};
</script>

<style scoped>
#square-button-container {
  background-color: #336799;
  border-radius: 16px;
  cursor: pointer;
  height: 220px;
  margin: 28px;
  width: 220px;
}

#square-button-title {
  color: #ffffff;
  font-size: 20px;
  margin: 0 5px;
  width: 190px;
  text-align: center;
}

@media (max-width: 540px) {
  #square-button-container {
    margin: 10px;
  }
}
</style>
